<script setup>
import useClients from '@/hooks/useClients';
import useWarehouses from '@/hooks/useWarehouses';
import ClientLayout from '@/layouts/ClientLayout.vue';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const route = useRoute();
const toast = useToast();

const { client, fetchClient, updateClient } = useClients();
const { warehouses, fetchWarehouses } = useWarehouses();
onMounted(async () => {
    await fetchClient(route.params.id);
    await fetchWarehouses()
})

const save = async () => {
    const response = await updateClient(route.params.id, client.value);
    if (response) {
        toast.success("Client settings updated successfully.");
        await fetchClient(route.params.id);
    }
}
</script>

<template>
    <ClientLayout :client="client">
        <div class="mx-4" v-if="client">
            <div class="text-h4 mt-4">Settings for <span class="font-weight-light">{{client?.name}}</span></div>
            <v-card class="mt-4">
                <v-card-title class="bg-surface-light">Portal Access</v-card-title>
                <v-divider />
                <v-row class="pa-2 pl-4 align-center">
                    <v-col cols="4">
                        Warehouse
                    </v-col>
                    <v-col cols="8">
                    <v-autocomplete
                        density="compact"
                        v-model="client.warehouse_id"
                        :items="warehouses?.data"
                        item-title="name"
                        item-value="id"
                        id="warehouse"
                        hide-details="auto"
                        placeholder="Select a warehouse..."
                        name="warehouse"
                        variant="outlined"
                    ></v-autocomplete>
                    </v-col>
                    <v-divider />
                    <v-col cols="4">
                        Transportation
                    </v-col>
                    <v-col cols="8">
                        <v-switch 
                            inset 
                            v-model="client.transport_access"
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                    <v-divider />
                    <v-col cols="4">
                        SLO
                    </v-col>
                    <v-col cols="8">
                        <v-switch 
                            inset 
                            v-model="client.slo_access"
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                    <v-divider />
                    <v-col cols="4">
                        Fulfillment
                    </v-col>
                    <v-col cols="8">
                        <v-switch 
                            inset 
                            v-model="client.ecomm_access"
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                </v-row>
            </v-card>

            <!-- <v-card class="mt-4">
                <v-card-title class="bg-surface-light">Brokers</v-card-title>
                <v-divider />
                <v-row class="pa-2 pl-4 align-center">
                    <v-col cols="4">
                        Broker Account?
                    </v-col>
                    <v-col cols="8">
                        <v-switch 
                            inset 
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                </v-row>
            </v-card> -->

            <v-card class="mt-4">
                <v-card-title class="bg-surface-light">Billing</v-card-title>
                <v-divider />
                <v-row class="pa-2 pl-4 align-center">
                    <v-col cols="4">
                        QuickBooks ID
                    </v-col>
                    <v-col cols="8">
                        <v-text-field 
                            v-model="client.qb_id"
                            type="number"
                            variant="outlined" 
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                <v-divider />
                <v-col cols="4">
                        Credit Hold
                    </v-col>
                    <v-col cols="8">
                        <v-switch 
                            inset 
                            v-model="client.credit_hold"
                            hide-details 
                            color="primary"
                            density="compact"
                        />
                    </v-col>
                </v-row>
            </v-card>

            <div class="mt-4">
                <v-btn 
                    class="w-100" 
                    size="large"
                    color="primary"
                    @click="save"
                >Save</v-btn>
            </div>
        </div>
    </ClientLayout>
</template>

<style scoped>
.text-h4 {
    /*needs to be inside scope different font used in other components */
    font-family: 'Source Sans 3', 'sans-serif' !important;
}

</style>